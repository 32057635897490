import React from "react";
import Header from "../partials/header"
import Footer from "../partials/footer"
import Brandlist from "../components/brandlist"
import AruzySeo from "../components/aruzySeo";
import HeaderTitle from "../components/HeaderTitle";

const Brand = () => {
    return (
        <main>
            <Header/>
            <div className="App topattachbgsection">
                <AruzySeo title={"Brands"}/>
                <div className="container changeheading mb-30">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="cat-header text-center">
                                <HeaderTitle title={'Brands'}/>
                            </div>
                            <Brandlist/>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </main>
    );
};

export default Brand;