import * as React from "react"
import {useState, useEffect} from 'react';
import axios from 'axios';
import {Link} from "gatsby"
import houseIcon from "../images/newimage/house.png"

const Brandlist = (props, data) => {
    const [brands, setBrands] = useState(false);
    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = () => {
        axios.get(`${process.env.API_URL}/brands`).then((res) => {
            res.data.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                return 0;
            });
            console.log(res.data);
            setBrands(res.data);
        }).catch((error) => {
            console.log(error);
        });
    };

    if (brands === false) {
        return (<>Loading...</>);
    }

    return (<div className="row">
        {brands.map((r) => (
            <div key={"brand-" + r.id} className="col-md-3 voucherslisting">
                <div className="card voucher blogtag brandSection">
                    <Link to={"/brand/Brand_" + r.id}>
                        <img className="brandimage" src={r.image !== null ? `${process.env.API_URL}` + r.image.url : "https://via.placeholder.com/350x200.png?text=" + r.name} alt={r.name}/>
                        <div className="card-body">
                            <h3 className="retailer">
                                <img className="pr-4" src={houseIcon} alt={r.name}/>
                                {r.name}
                            </h3>
                        </div>
                    </Link>
                </div>
            </div>
        ))}
    </div>)
}


export default Brandlist
